<template>
    <div>
    <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
        <router-link to="/settings" class="d-inline-block mt-2"><i class="mico-feather-12 font-sm text-white"></i></router-link>
        <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">Change Password</h4>
    </div>
    <div class="card-body p-lg-5 p-4 w-100 border-0">
            <div class="row profile-edit">
            <div class="col-lg-10">
                <Form  id="edit-profile-info" method="post" @submit="changePassword">
                    <div class="mb-3">
                        <label for="userpwd" class="form-label">Current Password</label>
                        <Field ref="userpwd"  type="password" class="form-control" id="userpwd" name="userpwd" placeholder="" v-model="userData.userpwd">
                        </Field>
                        <ErrorMessage name="useremail" />
                    </div>
                    <div class="mb-3">
                        <label for="newpassword" class="form-label">New Password </label>
                        <br>
                        <small>minimum 8 length , At least one Uppercase , At least one special character</small>
                        <Field ref="newpassword"  type="password" class="form-control" id="newpassword" name="newpassword" placeholder=""  v-model="userData.newpassword" >
                        </Field>
                        <ErrorMessage name="useremail" />
                    </div>
                    <div class="mb-3"> <p>{{profileEditstatus}}</p></div>
                    <div class="mb-3"><button class="btn" type="submit">Change Password</button></div>
                </Form>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from '@/plugins/axios'
import noty from 'noty'
export default {
    components: { Form, Field, ErrorMessage},
     data () {
        return {
            userData:{},
            profileEditstatus:'',
            loader:false
        }
    },
    methods: {
        changePassword:function(values){
            this.profileEditstatus ="";
            if( !(values.userpwd || values.newpassword)) return this.profileEditstatus = 'Fields cant be empty!'
            let self= this
            document.querySelector('#edit-profile-info .btn').disabled=true;
            axios.post('/api/auth/changepwd', {
                userpwd:values.userpwd,
                newpassword: values.newpassword,
            }).then(async response => {
                document.querySelector('#edit-profile-info .btn').disabled=false;
                self.userData={}
                new noty({text: 'Password updated' , type:"success", layout:"bottomRight" , timeout:1000}).show();
            }).catch(error=>{
                self.profileEditstatus =error.response.data.message
                new noty({text: error.response.data.message , type:"error", layout:"bottomRight" , timeout:1000}).show();
                document.querySelector('#edit-profile-info .btn').disabled=false;
            })
        },
    },
    created: function () {
    },
}
</script>